import { createApp, h } from "vue";
import App from "./App.vue";
import { router } from "./router"; // 导入路由文件

const app = createApp({
  render: () => h(App),
});

// 屏蔽警告信息
app.config.warnHandler = () => null;

app.use(router);
app.mount("#app");
