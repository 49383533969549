<template>
  <router-view v-if="isRouterAlive" />
</template>

<script>
export default {
  name: "App",

  provide() {
    //提供reload方法
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    // this.$confirm(
    //   "亲爱的各位树友：<br> 为保证模型树网站的运行稳定和服务质量，本次模型树网站服务器将于北京时间2022年5月23日晚23点停机，进行相关功能升级。预计维护时间为晚上23点至次日凌晨1点，<br>请各位树友相互转告，并提前留意时间，23日23点之前保存文档。避免编辑的文档丢失等。<br>对于维护期间给您带来的不便，敬请谅解。<br><br><br>模型树官方 2022年5月22日",
    //   "2022年5月23日 网站维护升级公告",
    //   {
    //     dangerouslyUseHTMLString: true,
    //     confirmButtonText: "确定",
    //     showCancelButton: false,
    //   }
    // );
  },
  methods: {
    //刷新方法
    reload() {
      // 通过 this.isRouterAlive 控制 router-view 达到刷新效果
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
#app {
  font-family: PingFang SC;
  /* height: 100vh; */
}
</style>
