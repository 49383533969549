import { createRouter, createWebHistory } from "vue-router";
const index = () => import("@/views/pc/login/index.vue");
const routes = [
  {
    path: "/wechat",
    name: "wechat",
    component: index,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});
